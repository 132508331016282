import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import BlockContent from "@sanity/block-content-to-react"
import serializers from "../components/serializers"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ApplicationForm from "../components/jobContactForm"
import { Link as LinkScroll } from "react-scroll"
import PageOpening from "../components/pageOpening"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      sanityPages(id: { eq: "e2323800-9342-596f-a107-de023a708ceb" }) {
        seo {
          seo_title
          meta_description
        }
        title
        _rawBody
        _rawSubtitle
        templateList
        mainImage {
          alt
          video
          image {
            asset {
              fluid(maxWidth: 600) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
      allSanityJobs {
        edges {
          node {
            title
            filian
            slug {
              current
            }
            file {
              asset {
                url
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      {data.sanityPages.seo && (
        <SEO
          title={data.sanityPages.seo.seo_title}
          metaDescription={data.sanityPages.seo.meta_description}
          seoTitle={data.sanityPages.seo.seo_title}
        />
      )}
      <section className="index jobs">
        <PageOpening data={data} />

        <BlockContent
          className={data.sanityPages.templateList}
          blocks={data.sanityPages._rawBody}
          projectId="ppd47c0u"
          dataset="production"
          serializers={serializers}
        />

        {data.allSanityJobs && (
          <div className="job_section">
            <h2 className="job_header animation--fade-up animation">
              Job hos Zignifikant
            </h2>
            <p
              className="animation--fade-up animation"
              style={{
                textAlign: "center",
                marginTop: "-30px",
                marginBottom: "40px",
              }}
            >
              Ansøg stillingerne i bunden af siden
            </p>
            <div className="joblist">
              {data.allSanityJobs.edges.map(function(job) {
                return (
                  <div className="job animation--fade-up animation">
                    <div className="right_wrapper">
                      <h3 className="job_title">{job.node.title}</h3>
                      <p className="branch">{job.node.filian}</p>
                    </div>
                    <a className="blue_button" href={job.node.slug.current}>
                      Læs mere
                    </a>
                  </div>
                )
              })}

              <div className="job animation--fade-up animation">
                <div className="right_wrapper">
                  <h3 className="job_title">Ansøg uopfordret</h3>
                  <p className="branch">Aarhus</p>
                </div>
                <LinkScroll
                  className="blue_button"
                  activeClass="active"
                  to="application-form"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={500}
                  style={{ cursor: "pointer" }}
                >
                  Ansøg her
                </LinkScroll>
              </div>
            </div>
          </div>
        )}
      </section>
      <ApplicationForm></ApplicationForm>
    </Layout>
  )
}

export default IndexPage

// function checkJobs() {
//   const jobList = document.querySelector(".joblist")

//   if (jobList.innerHTML === "") {
//     document.querySelector(".job_header").style.display = "none"
//   }
// }
